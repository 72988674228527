<template>
  <div class="main_page">
    <app-navbar></app-navbar>
    <div class="page">
      <div>
        <div class="page-title">
          <h1>My Dashboard</h1>
        </div>
        <div class="page-content">
          <component :is="dashboardComponent"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MemberDashboard from "@/views/dashboard/components/dashboard-member.vue";
import OcaaDashboard from "@/views/dashboard/components/dashboard-ocaa.vue";

export default {
  name: "dashboard",
  components: {
    'app-navbar': () => import('@/components/app-navbar.vue'),
    MemberDashboard,
    OcaaDashboard
  },
  data: function() {
    return {
      dashboardComponent: null
    };
  },
  mounted() {
    let user = this.$store.getters.getCurrentUser;
    this.dashboardComponent = (!user.isAdmin) ? MemberDashboard : OcaaDashboard;
  }
};
</script>
