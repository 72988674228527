<template>
  <fragment>
    <div class="row">
      <div class="col-lg col-md-6 col-sm-12">
        <dashboard-overview
          :title="'Projects Posted'"
          :color="'green'"
          :icon="'folder'"
          :data="projectOverview.projectsPosted"
          compare-by-percentage
        ></dashboard-overview>
      </div>

      <div class="col-lg col-md-6 col-sm-12">
        <dashboard-overview
          :title="'Number of Members'"
          :color="'yellow'"
          :icon="'people'"
          :data="projectOverview.numberOfMembers"
          no-compare
        ></dashboard-overview>
      </div>

      <div class="col-lg col-md-6 col-sm-12">
        <dashboard-overview
          :title="'Expired Members'"
          :color="'red'"
          :icon="'exclamation'"
          :data="projectOverview.expiredMembers"
          no-compare
        ></dashboard-overview>
      </div>

      <div class="col-lg col-md-6 col-sm-12">
        <dashboard-overview
          :title="'Active Projects'"
          :color="'blue'"
          :icon="'list'"
          :data="projectOverview.activeProjects"
          no-compare
        ></dashboard-overview>
      </div>
    </div>
    <div class="row">
      <div class="col-lg">
        <div class="card border-0">
          <div class="card-body">
            <h2 class="card-title mb-5">My Project Calendar</h2>
            <div class="row">
              <div class="col-lg-6">
                <dashboard-calendar :events="events" @fetch-events="onCalendarDateChanged"></dashboard-calendar>
              </div>
              <div class="col-lg-6">
                <dashboard-member-event v-for="(event, i) in displayEvents" :key="'calendar-event-' + i" :event="event"></dashboard-member-event>
                <div v-if="!displayEvents || !displayEvents.length" class="alert alert-info" role="alert">
                  No upcoming events for this month
                </div>
              </div>
            </div>
            <div class="row mb-3 pt-3" style="border-top: 1px solid #ccc;">
              <div class="col">
                <strong>Calendar Key</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-1">
                <div class="vuecal__event closing-date" style="width: 25px;">
                  <div class="vuecal__event-title">&nbsp;</div>
                </div>
              </div>
              <div class="col-3">Closing Date</div>
              <div class="col-1">
                <div class="vuecal__event site-meeting" style="width: 25px;">
                  <div class="vuecal__event-title">&nbsp;</div>
                </div>
              </div>
              <div class="col-3">Site Meeting</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg">
        <dashboard-list no-border :title="'New Projects from bids&tenders'" :records="bidProjects" :total="bidProjectsTotal"
            @fetch="onRequestNewBTProjects" ref="bidProjectsList">
          <template v-slot:default="{ record }">
            <div class="mr-auto">
              <p class="m-0">{{ record.project.ocaaNumber }} - {{ record.project.title }} - {{ record.project.owner.name }}</p>
              <p class="m-0 small-grey">{{ record.project.closingDate | datetime('MMM d, yyyy h:mm a') }} {{ record.project.timeZone.code}}</p>
            </div>
            <router-link :to="'/project/info/' + record.project.id" class="d-flex justify-content-between align-items-center flex-column">
              <img class="mb-1" src="@/assets/document.svg" alt />
              <span class="small-grey">View</span>
            </router-link>
            <a href="#" class="d-flex justify-content-between align-items-center flex-column ml-3" @click.prevent="dismissBidProject(record.id)">
              <img class="mb-1" src="@/assets/red_x.svg" alt />
              <span class="small-grey">Dismiss</span>
            </a>
          </template>
        </dashboard-list>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg">
        <dashboard-list no-border :title="'Addenda from bids&tenders'" :records="addendas" :total="addendasTotal"
            @fetch="onRequestAddendas" ref="addendasList">
          <template v-slot:default="{ record }">
            <div class="mr-auto">
              <p class="m-0">{{ record.ocaaNumber }} - {{ record.title }} - {{ record.owner }}</p>
              <p class="m-0 small-grey">{{ record.closingDate | datetime('MMM d, yyyy h:mm a') }} {{ record.timeZone.code }}</p>
            </div>
            <router-link :to="'/project/info/' + record.projectId" class="d-flex justify-content-between align-items-center flex-column">
              <img class="mb-1" src="@/assets/document.svg" alt />
              <span class="small-grey">View</span>
            </router-link>
            <a href="#" class="d-flex justify-content-between align-items-center flex-column ml-3" @click.prevent="dismissAddenda(record.id)">
              <img class="mb-1" src="@/assets/red_x.svg" alt />
              <span class="small-grey">Dismiss</span>
            </a>
          </template>
        </dashboard-list>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg">
        <dashboard-list no-border :title="'Closing Bids'" :records="closingBids" :total="closingBidsTotal" @fetch="onRequestClosingBids" ref="closingBidsList">
          <template v-slot:default="{ record }">
            <div class="mr-auto">
              <p class="m-0">{{ record.ocaaNumber }} - {{ record.title }} - {{ record.owner }}</p>
              <p class="m-0 small-grey">{{ record.closingDate | datetime('MMM d, yyyy h:mm a') }} {{ record.timeZone.code }}</p>
            </div>
            <router-link :to="'/project/info/' + record.id" class="d-flex justify-content-between align-items-center flex-column">
              View More
            </router-link>
          </template>
          <template v-slot:no-records>
            No bids closing today -- Sit back and relax!
          </template>
        </dashboard-list>
      </div>
    </div>

    <div class="row">
      <div class="col-lg">
        <dashboard-list no-border :title="'Expiring Members'" :records="expiringMembers" display-all>
          <template v-slot:default="{ record }">
            <div class="mr-auto">
              <p class="m-0">{{ record.name }}</p>
            </div>
            <span href="#" class="d-flex justify-content-between align-items-center flex-column">
              {{ record.expiryDate | datetime('MMMM dd, yyyy') }}
            </span>
          </template>
        </dashboard-list>
      </div>
    </div>
  </fragment>
</template>

<script>
import { DashboardService, DismissProjectService } from "@/services/";
import { date as dateSort } from "@/utils/sort";
import Toast from "@/utils/toast";
import { EventBus } from "@/event-bus/event-bus";
import { format } from 'date-fns';

export default {
  name: "dashboard-ocaa",
  components: {
    'dashboard-calendar': () => import('@/views/dashboard/components/dashboard-calendar.vue'),
    'dashboard-overview': () => import('@/views/dashboard/components/dashboard-overview.vue'),
    'dashboard-list': () => import('@/views/dashboard/components/dashboard-list.vue'),
    'dashboard-member-event': () => import('@/views/dashboard/components/dashboard-member-event.vue'),
  },
  data: function() {
    return {
      projectOverview: { projectsPosted: [], numberOfMembers: [], expiredMembers: [], activeProjects: [] },
      events: [],
      bidProjects: [],
      bidProjectsTotal: 0,
      addendas: [],
      addendasTotal: 0,
      closingBids:[],
      closingBidsTotal: 0,
      expiringMembers: [],
    };
  },
  computed: {
    displayEvents() {
      return this.events.filter(event => event.eventDate && Date.parse(event.eventDate) > Date.now()).slice(0, 8);
    },
    getMonthStartEndDates() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth();

      return {
        start: format(new Date(year, month, 1), 'yyyy-MM-dd'),
        end: format(new Date(year, month + 1, 0), 'yyyy-MM-dd')
      };
    },
  },
  methods: {
    async dismissBidProject(id) {
      await DismissProjectService.dismiss(id);
      this.onRequestNewBTProjects(this.$refs.bidProjectsList.displayRecordsCount);
    },
    async dismissAddenda(id) {
      await DismissProjectService.dismiss(id);
      this.onRequestAddendas(this.$refs.addendasList.displayRecordsCount);
    },
    async dismissClosingBid(id) {
      await DismissProjectService.dismiss(id);
      this.onRequestClosingBids(this.$refs.closingBidsList.displayRecordsCount);
    },
    fetchAddendas(pageSize) {
      return DashboardService.admin.dismissalbtaddenda({
        pageSize: pageSize,
        page: 1
      });
    },
    fetchCalendarEvents(startDate, endDate) {
      return DashboardService.calendar({ startDate, endDate });
    },
    fetchClosingBids(pageSize) {
      return DashboardService.admin.search({
        closing: {
          start: new Date().toDateString(),
          end: new Date().toDateString()
        },
        pageSize: pageSize,
        page: 1
      });
    },
    fetchNewBTProjects(pageSize) {
      return DashboardService.admin.dismissalsearch({
        codes: ["bt_project"],
        pageSize: pageSize,
        page: 1
      });
    },

    async onCalendarDateChanged({ startDate, endDate }) {
      let response = await this.fetchCalendarEvents(startDate, endDate);
      this.events = (response && response.data) || [];

      if (this.events.length) {
        this.events.sort((a, b) => dateSort(a.eventDate, b.eventDate));
      }
    },
    async onRequestAddendas (pageSize) {
      let response = await this.fetchAddendas(pageSize);
      let data = (response && response.data) || { results: [], total: 0 };
      this.addendas = data.results;
      this.addendasTotal =  data.total;
    },
    async onRequestClosingBids (pageSize) {
      let response = await this.fetchClosingBids(pageSize);
      let data = (response && response.data) || { results: [], total: 0 };
      this.closingBids = data.results;
      this.closingBidsTotal =  data.total;
    },
    async onRequestNewBTProjects(pageSize) {
      let response = await this.fetchNewBTProjects(pageSize);
      let data = (response && response.data) || { results: [], total: 0 };
      this.bidProjects = data.results;
      this.bidProjectsTotal = data.total;
    },
  },
  mounted () {
    let { start, end } = this.getMonthStartEndDates;

    Promise.all([
      DashboardService.admin.overview(),
      this.fetchCalendarEvents(start, end),
      this.fetchNewBTProjects(3),
      this.fetchAddendas(3),
      this.fetchClosingBids(3)
    ])
    .then(response => {
      // overview
      this.projectOverview.projectsPosted = response[0].data.projectsPosted.map(i => { return { key: i.year, value: i.total } });
      this.projectOverview.numberOfMembers = response[0].data.numberOfMembers.map(i => { return { key: i.name, value: i.total } });
      this.projectOverview.expiredMembers = response[0].data.expiredMembers.map(i => { return { key: i.name, value: i.total } });
      this.projectOverview.activeProjects = response[0].data.activeProjects.map(i => { return { key: i.name, value: i.total } });
      this.events = response[1].data.sort((a, b) => dateSort(a.eventDate, b.eventDate));
      this.bidProjects = response[2].data.results;
      this.bidProjectsTotal = response[2].data.total;
      this.addendas = response[3].data.results;
      this.addendasTotal = response[3].data.total;
      this.closingBids = response[4].data.results;
      this.closingBidsTotal = response[4].data.total;
      this.expiringMembers = response[0].data.expiringMembers;

      EventBus.$emit('page-loading', false);
    })
    .catch(() => {
      this.projectOverview.projectsPosted = [];
      this.projectOverview.numberOfMembers = [];
      this.projectOverview.expiredMembers = [];
      this.projectOverview.activeProjects = [];
      this.events = [];
      this.bidProjects = [];
      this.bidProjectsTotal = 0;
      this.addendas = [];
      this.addendasTotal = 0;
      this.closingBids = [];
      this.closingBidsTotal = 0;
      this.expiringMembers = [];

      let message = this.$createElement('span', {}, ['Oops! An error has occured and some data could not be loaded', this.$createElement('br'), 'Please contact an administrator']);
      Toast.danger(this, [message], { autoHideDelay: 7500 });

      EventBus.$emit('page-loading', false);
    });
  }
};
</script>
