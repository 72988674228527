<template>
  <fragment>
    <div>
      <div class="row">
        <div v-bind:class="'col-lg-4'">
          <h2 class="mb-4" v-if="!excludeZones">My Membership</h2>
          <div class="d-flex flex-column flex-sm-row align-items-center mb-3 text-center text-sm-left">
            <app-avatar class="mb-2 mb-sm-0 mr-3" :name="currentUserName" :size="80"></app-avatar>
            <div class="d-flex flex-column justify-content-between">
              <div v-if="user && user.companies && user.companies.length">
                <h3 class="mb-0">{{ currentUserName }}</h3>
                <span v-for="(company, index) in user.companies" :key="'user-company-display-' + index">{{ company.name }}</span>
              </div>
            </div>
          </div>
          <div id="list-group-zones" class="list-group mb-3" v-if="!excludeZones">
            <dashboard-member-zone v-for="(zone, index) in zones"
                                   :parent="'list-group-zones-' + index"
                                   :key="'dashboard-zone-' + index"
                                   :zone="zone"></dashboard-member-zone>
          </div>
          <app-adspace code="DASHBOARD_MEMBER" size="4x3"></app-adspace>
        </div>

        <div v-bind:class="'col-lg-8'">
          <h2 class="mb-4">My Project Calendar</h2>
          <div class="row">
            <div class="col-lg-6">
              <dashboard-calendar :events="events" @fetch-events="onCalendarDateChanged"></dashboard-calendar>
            </div>
            <div class="col-lg-6">
              <dashboard-member-event v-for="(event, i) in displayEvents" :key="'calendar-event-' + i" :event="event"></dashboard-member-event>
              <div v-if="!displayEvents || !displayEvents.length" class="alert alert-info" role="alert">
                No upcoming events for this month
              </div>
            </div>
          </div>

          <div class="row mb-3 pt-3" style="border-top: 1px solid #ccc;">
            <div class="col">
              <strong>Calendar Key</strong>
            </div>
          </div>

          <div class="row">
            <div class="col-1">
              <div class="vuecal__event closing-date" style="width: 25px;">
                <div class="vuecal__event-title">&nbsp;</div>
              </div>
            </div>
            <div class="col-3">Closing Date</div>
            <div class="col-1">
              <div class="vuecal__event site-meeting" style="width: 25px;">
                <div class="vuecal__event-title">&nbsp;</div>
              </div>
            </div>
            <div class="col-3">Site Meeting</div>
          </div>
        </div>
      </div>

      <div class="row mt-5" v-if="showNews">
        <div class="col-lg">
          <dashboard-list :title="'News'" no-border :records="newsItems">
            <template v-slot:default="{ record }">
              <div class="mr-auto">
                <p class="m-0">{{ record.title }}</p>
                <p class="m-0 small-grey">{{ record.summary }}</p>
              </div>
              <a :href="record.link" class="d-flex justify-content-between align-items-center align-self-center flex-column" style="width: 80px" target="_blank">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">View</span>
              </a>
            </template>
            <template v-slot:no-records>
              There is no news to share.
            </template>
          </dashboard-list>
        </div>
      </div>

      <div class="row mt-5" v-if="showMyProjectsList">
        <div class="col-lg">
          <dashboard-list :title="'My Created Projects'"
                          no-border
                          :records="projectCreatedByMe"
                          :total="projectCreatedByMeTotal"
                          @fetch="onRequestProjectCreatedByMe"
                          ref="productCreatedByMeList">
            <template v-slot:default="{ record }">
              <div class="mr-auto">
                <p class="m-0">{{ getProjectDetailsHeader(record) }}</p>
                <p class="m-0 small-grey">{{ getProjectClosingDate(record) }}</p>
              </div>
              <router-link :to="'/project/info/' + record.id" class="d-flex justify-content-between align-items-center flex-column" style="width: 80px">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">View</span>
              </router-link>
            </template>
            <template v-slot:no-records>
              No projects created. Create a
              <router-link to="/project/new"> new project </router-link>.
            </template>
          </dashboard-list>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg">
          <dashboard-list :title="'My Invited Projects'"
                          no-border
                          :records="projectInvited"
                          :total="projectInvitedTotal"
                          @fetch="onRequestProjectInvited"
                          ref="productInvitedList">
            <template v-slot:default="{ record }">
              <div class="mr-auto">
                <p class="m-0">{{ getProjectDetailsHeader(record) }}</p>
                <p class="m-0 small-grey">{{ getProjectClosingDate(record) }}</p>
              </div>
              <router-link :to="'/project/info/' + record.id" class="d-flex justify-content-between align-items-center flex-column" style="width: 80px">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">View</span>
              </router-link>
            </template>
            <template v-slot:no-records>
              No invitations received.  Go to
              <router-link to="/project/search">projects & bid</router-link>.
            </template>
          </dashboard-list>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg">
          <dashboard-list :title="'My Project Updates'"
                          no-border
                          :records="projectUpdates"
                          :total="projectUpdatesTotal"
                          filtered
                          :filterGroups="projectUpdatesFilter"
                          @filter-changed="onFilterChangeProjectUpdates"
                          @fetch="onRequestProjectUpdates"
                          ref="productUpdatesList">
            <template v-slot:default="{ record }">
              <div class="mr-auto">
                <p class="m-0">{{ record.type.name }}</p>
                <p class="m-0 small-grey">
                  {{ record.project.ocaaNumber }} - {{ record.project.title }} - {{ getProjectClosingDate(record.project) }}
                </p>
              </div>
              <router-link :to="'/project/info/' + record.project.id" class="d-flex justify-content-between align-items-center flex-column" style="width: 80px">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">View</span>
              </router-link>
              <a href="#" class="d-flex justify-content-between align-items-center flex-column ml-3" style="width: 80px"
                 @click.prevent="dismissProjectUpdates(record.id)">
                <img class="mb-1" src="@/assets/red_x.svg" alt />
                <span class="small-grey">Dismiss</span>
              </a>
            </template>
          </dashboard-list>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-lg">
          <dashboard-list :title="'Projects I am Following'"
                          no-border
                          :records="projectFollowing"
                          :total="projectFollowingTotal"
                          @fetch="onRequestProjectFollowing">
            <template v-slot:default="{ record }">
              <div class="mr-auto">
                <p class="m-0">{{ record.ocaaNumber }} - {{ record.title }} - {{ record.owner }} </p>
                <p class="m-0 small-grey">{{ getProjectClosingDate(record) }}</p>
              </div>
              <router-link :to="'/project/info/' + record.id" class="d-flex justify-content-between align-items-center flex-column" style="width: 80px">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">View</span>
              </router-link>
            </template>
          </dashboard-list>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-lg">
          <dashboard-list :title="'Opportunity Matching'"
                          no-border
                          :records="opportunityMatching"
                          :total="opportunityMatchingTotal"
                          @fetch="onRequestOpportunityMatching"
                          ref="opportunityMatchingList">
            <template v-slot:default="{ record }">
              <div class="mr-auto">
                <p class="m-0">{{ getProjectDetailsHeader(record.project) }}</p>
                <p class="m-0 small-grey">{{ getProjectClosingDate(record.project) }}</p>
              </div>
              <router-link :to="'/project/info/' + record.project.id" class="d-flex justify-content-between align-items-center flex-column" style="width: 80px">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">View</span>
              </router-link>
              <a href="#" class="d-flex justify-content-between align-items-center flex-column ml-3" style="width: 80px"
                 @click.prevent="dismissOpportunityMatching(record.id)">
                <img class="mb-1" src="@/assets/red_x.svg" alt />
                <span class="small-grey">Dismiss</span>
              </a>
            </template>
          </dashboard-list>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-lg">
          <dashboard-list :title="'My Product Matching'"
                          no-border
                          :records="productMatching"
                          :total="productMatchingTotal"
                          @fetch="onRequestProductMatching"
                          ref="productMatchingList">
            <template v-slot:default="{ record }">
              <div class="mr-auto">
                <p class="m-0">{{ getProjectDetailsHeader(record.project) }}</p>
                <p class="m-0 small-grey">{{ getProjectClosingDate(record.project) }}</p>
              </div>
              <router-link :to="'/project/info/' + record.project.id" class="d-flex justify-content-between align-items-center flex-column" style="width: 80px">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">View</span>
              </router-link>
              <a href="#" class="d-flex justify-content-between align-items-center flex-column ml-3" style="width: 80px"
                 @click.prevent="dismissProductMatching(record.id)">
                <img class="mb-1" src="@/assets/red_x.svg" alt />
                <span class="small-grey">Dismiss</span>
              </a>
            </template>
          </dashboard-list>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-lg">
          <dashboard-list :title="'Bid Results'" no-border :records="bidResults" :total="bidResultsTotal" @fetch="onRequestBidResults" ref="bidResultsList">
            <template v-slot:default="{ record }">
              <div class="mr-auto">
                <p class="m-0">{{ record.ocaaNumber }} - {{ record.title }} - {{ record.owner }} </p>
                <p class="m-0 small-grey">{{ getProjectClosingDate(record) }}</p>
              </div>
              <router-link :to="'/project/info/' + record.id" class="d-flex justify-content-between align-items-center flex-column" style="width: 80px">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">View</span>
              </router-link>
              <span class="d-flex justify-content-between align-items-center flex-column ml-3" style="width: 80px">
                <img class="mb-1" :src="require(`@/assets/${ record.tenderStage.code === 'awarded' ? 'award' : 'finger' }.svg`)" alt />
                <span class="small-grey">{{ record.tenderStage.name }}</span>
              </span>
            </template>
          </dashboard-list>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import { DashboardService, DismissProjectService, NewsService, UserService } from "@/services/";
  import JwtHelper from "@/utils/jwtHelper";
  import { date as dateSort } from "@/utils/sort";
  import { DateTimeFilter } from "@/filters/datetime";
  import Toast from "@/utils/toast";
  import { EventBus } from "@/event-bus/event-bus";

  export default {
    name: "dashboard-member",
    components: {
      "app-avatar": () => import('@/components/app-avatar.vue'),
      'app-adspace': () => import("@/components/app-adspace.vue"),
      'dashboard-calendar': () => import('@/views/dashboard/components/dashboard-calendar.vue'),
      'dashboard-list': () => import('@/views/dashboard/components/dashboard-list.vue'),
      'dashboard-member-event': () => import('@/views/dashboard/components/dashboard-member-event.vue'),
      'dashboard-member-zone': () => import('@/views/dashboard/components/dashboard-member-zone.vue'),
    },
    data: function () {
      return {
        bidResults: [],
        bidResultsTotal: 0,
        events: [],
        opportunityMatching: [],
        opportunityMatchingTotal: 0,
        projectCreatedByMe: [],
        projectCreatedByMeTotal: 0,
        productMatching: [],
        productMatchingTotal: 0,
        projectFollowing: [],
        projectFollowingTotal: 0,
        projectUpdates: [],
        projectUpdatesTotal: 0,
        projectUpdatesFilter: [
          { display: 'Addenda Update', code: 'addenda_update', active: true },
          { display: 'Closing Date Update', code: 'closing_date_update', active: true },
          { display: 'Site Meetings Update', code: 'site_meetings_update', active: true },
          { display: 'Status Change Update', code: 'status_change_update', active: true }
        ],
        projectInvited: [],
        projectInvitedTotal: 0,
        newsItems: [],
        newsItemsTotal: 0,
        user: null,
        zones: []
      };
    },
    watch: {
      showNews: function(value) {
        value && this.fetchNewsItems();
      }
    },
    computed: {
      currentUserName() {
        return this.user ? `${this.user.firstName} ${this.user.lastName}` : 'Unknown User';
      },
      displayEvents() {
        return this.events.filter(event => new Date(event.eventDate) > new Date()).slice(0, 5);
      },
      excludeZones() {
        return (this.getExcludeZonesFromDashboard || '').toUpperCase() === 'TRUE';
      },
      getMonthStartEndDates() {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();

        return {
          start: new Date(year, month, 1),
          end: new Date(year, month + 1, 0)
        };
      },
      selectedProjectUpdateFilterCodes() {
        return this.projectUpdatesFilter.filter(i => i.active).map(i => i.code);
      },
      showMyProjectsList() {
        return this.$permissions().hasAddPrivateProjectPermission;
      },
      showNews () {
        return this.getUseDisplayNews;
      },
      ...mapGetters([
        "getZones",
        "getExcludeZonesFromDashboard",
        "getUseDisplayNews"
      ])
    },
    methods: {
      getProjectDetailsHeader(project) {
        let ownerName = project.owner ? ` - ${project.owner.name}` : '';

        return `${project.ocaaNumber} - ${project.title}${ownerName}`;
      },
      getProjectClosingDate(project) {
        return `${DateTimeFilter(project.closingDate, 'MMM d, yyyy h:mm a')} ${project.timeZone.code}`;
      },
      async dismissOpportunityMatching(id) {
        await DismissProjectService.dismiss(id);
        this.onRequestOpportunityMatching(
          this.$refs.opportunityMatchingList.displayRecordsCount
        );
      },
      async dismissProductMatching(id) {
        await DismissProjectService.dismiss(id);
        this.onRequestProductMatching(
          this.$refs.productMatchingList.displayRecordsCount
        );
      },
      async dismissProjectUpdates(id) {
        await DismissProjectService.dismiss(id);
        this.onRequestProjectUpdates(
          this.$refs.productUpdatesList.displayRecordsCount
        );
      },

      fetchCalendarEvents(startDate, endDate) {
        return DashboardService.calendar({
          startDate: startDate.toLocaleDateString(),
          endDate: endDate.toLocaleDateString()
        });
      },
      fetchProjectCreatedByMe(pageSize) {
        return DashboardService.member.createdprojects({
          pageSize: pageSize,
          page: 1,
          sortOrder: 'CreatedOn',
          sortDirection: 'asc'
        });
      },
      fetchProjectInvited(pageSize) {
        return DashboardService.member.invitedprojects({
          pageSize: pageSize,
          page: 1,
          sortOrder: 'ClosingDate',
          sortDirection: 'asc'
        });
      },
      fetchProjectUpdates(pageSize) {
        return DashboardService.member.dismissalsearch({
          codes: this.selectedProjectUpdateFilterCodes,
          pageSize: pageSize,
          page: 1,
          sortOrder: 'CreatedOn',
          sortDirection: 'desc'
        });
      },
      fetchProjectFollowing(pageSize) {
        return DashboardService.member.search({
          provideFollowedOnly: true,
          tenderStageId: "BA1408EB-83D9-4C94-A2BA-6D9436773B36",
          pageSize: pageSize,
          page: 1,
          sortOrder: 'ClosingDate',
          sortDirection: 'asc'
        });
      },
      fetchOpportunityMatching(pageSize) {
        return DashboardService.member.dismissalsearch({
          codes: ["opportunity_matching"],
          tenderStageCode: "open",
          pageSize: pageSize,
          page: 1,
          sortOrder: 'Project.ClosingDate',
          sortDirection: 'asc'
        });
      },
      fetchProductMatching(pageSize) {
        return DashboardService.member.dismissalsearch({
          codes: ["product_matching"],
          tenderStageCode: "open",
          pageSize: pageSize,
          page: 1,
          sortOrder: 'Project.ClosingDate',
          sortDirection: 'asc'
        });
      },
      fetchBidResults(pageSize) {
        let statuses = [
          "2501C6DC-361E-4970-B02B-40BE2D3D8B08", // Closed
          "80C4CD45-A72A-4D1E-877F-F39458F8EB02" // Awarded
        ];
        return DashboardService.member.search({
          tenderStageIds: statuses,
          pageSize: pageSize,
          page: 1,
          sortOrder: 'ClosingDate',
          sortDirection: 'asc'
        });
      },
      async fetchNewsItems () {
        let { data } = await NewsService.getDashboardNews();
        this.newsItems = data;
        this.newsItemsTotal = data.length;
      },

      async onCalendarDateChanged({ startDate, endDate }) {
        let response = await this.fetchCalendarEvents(startDate, endDate);
        this.events = (response && response.data) || [];

        if (this.events.length) {
          this.events.sort((a, b) => dateSort(a.eventDate, b.eventDate));
        }
      },
      onFilterChangeProjectUpdates(value) {
        let index = this.projectUpdatesFilter.findIndex(i => i.code === value);
        this.projectUpdatesFilter[index].active = !this.projectUpdatesFilter[index].active;

        this.onRequestProjectUpdates(this.$refs.productUpdatesList.displayRecordsCount);
      },
      async onRequestProjectCreatedByMe(pageSize) {
        let response = await this.fetchProjectCreatedByMe(pageSize);
        let data = (response && response.data) || { results: [], total: 0 };
        this.projectCreatedByMe = data.results;
        this.projectCreatedByMeTotal = data.total;
      },
      async onRequestProjectInvited(pageSize) {
        let response = await this.fetchProjectInvited(pageSize);
        let data = (response && response.data) || { results: [], total: 0 };
        this.projectInvited = data.results;
        this.projectInvitedTotal = data.total;
      },
      async onRequestProjectUpdates(pageSize) {
        let response = await this.fetchProjectUpdates(pageSize);
        let data = (response && response.data) || { results: [], total: 0 };
        this.projectUpdates = data.results;
        this.projectUpdatesTotal = data.total;
      },
      async onRequestProjectFollowing(pageSize) {
        let response = await this.fetchProjectFollowing(pageSize);
        let data = (response && response.data) || { results: [], total: 0 };
        this.projectFollowing = data.results;
        this.projectFollowingTotal = data.total;
      },
      async onRequestOpportunityMatching(pageSize) {
        let response = await this.fetchOpportunityMatching(pageSize);
        let data = (response && response.data) || { results: [], total: 0 };
        this.opportunityMatching = data.results;
        this.opportunityMatchingTotal = data.total;
      },
      async onRequestProductMatching(pageSize) {
        let response = await this.fetchProductMatching(pageSize);
        let data = (response && response.data) || { results: [], total: 0 };
        this.productMatching = data.results;
        this.productMatchingTotal = data.total;
      },
      async onRequestBidResults(pageSize) {
        let response = await this.fetchBidResults(pageSize);
        let data = (response && response.data) || { results: [], total: 0 };
        this.bidResults = data.results;
        this.bidResultsTotal = data.total;
      },

      ...mapActions([
        "fetchZones",
        "fetchClientSettings"
      ]),
    },
    mounted() {
      let { start, end } = this.getMonthStartEndDates;
      let user = JwtHelper.getUserToken();

      Promise.all([
        UserService.getById(user.id),
        DashboardService.member.membership(),
        this.fetchCalendarEvents(start, end),
        this.fetchProjectUpdates(3),
        this.fetchProjectFollowing(3),
        this.fetchOpportunityMatching(3),
        this.fetchProductMatching(3),
        this.fetchBidResults(3),
        this.fetchClientSettings(),
        this.showMyProjectsList ? this.fetchProjectCreatedByMe(3) : Promise.resolve({}),
        this.fetchProjectInvited(3),
        this.getUseDisplayNews ? this.fetchNewsItems() : Promise.resolve([]),
      ])
        .then(response => {
          this.user = response[0].data;
          this.zones = response[1].data;
          this.events = response[2].data.sort((a, b) => dateSort(a.eventDate, b.eventDate));

          // My Created Projects
          if (this.showMyProjectsList) {
            this.projectCreatedByMe = response[9].data.results;
            this.projectCreatedByMeTotal = response[9].data.total;
          }

          // My Invited Projects
          this.projectInvited = response[10].data.results;
          this.projectInvitedTotal = response[10].data.total;
          // Project Updates
          this.projectUpdates = response[3].data.results;
          this.projectUpdatesTotal = response[3].data.total;
          // Project Following
          this.projectFollowing = response[4].data.results;
          this.projectFollowingTotal = response[4].data.total;
          // Opportunity Matching
          this.opportunityMatching = response[5].data.results;
          this.opportunityMatchingTotal = response[5].data.total;
          // Product Matching
          this.productMatching = response[6].data.results;
          this.productMatchingTotal = response[6].data.total;
          // Bid Results
          this.bidResults = response[7].data.results;
          this.bidResultsTotal = response[7].data.total;

          EventBus.$emit('page-loading', false);
        })
        .catch(() => {
          this.user = null;
          this.zones = [];
          this.events = [];
          this.projectUpdates = [];
          this.projectUpdatesTotal = 0;
          this.projectFollowing = [];
          this.projectFollowingTotal = 0;
          this.opportunityMatching = [];
          this.opportunityMatchingTotal = 0;
          this.productMatching = [];
          this.productMatchingTotal = 0;
          this.bidResults = [];
          this.bidResultsTotal = 0;
          this.projectInvited = [];
          this.projectInvitedTotal = 0;

          let message = this.$createElement('span', {}, ['Oops! An error has occured and some data could not be loaded', this.$createElement('br'), 'Please contact an administrator']);
          Toast.danger(this, [message], { autoHideDelay: 7500 });

          EventBus.$emit('page-loading', false);
        });
    }
  };
</script>
